import React, { useState } from "react"
import { graphql } from "gatsby"
import { Row, Col, Nav } from "react-bootstrap"
import TabContainer from "react-bootstrap/TabContainer"
import TabContent from "react-bootstrap/TabContent"
import TabPane from "react-bootstrap/TabPane"
import Img from "gatsby-image"

import Layout from "../components/layout"

const ProductDetail = ({ data }) => {

    const products = data.gcms.productsApi[0]

    const [eventKey, setEventKey] = useState(products.photos[0].node.childImageSharp.id)

    const handleSelect = (eventkey, e) => {
        setEventKey(eventkey)
    }

    return (
        <Layout>
            <Row>
                <Col lg={5}>
                    <TabContainer
                        id="product-photo-container"
                        defaultActiveKey={products.photos[0].node.childImageSharp.id}
                    >
                        <Row>
                            <Col>
                                <TabContent>
                                    <TabPane eventKey={eventKey}>
                                        {products.photos.map((photo) => {
                                            if (eventKey === photo.node.childImageSharp.id) {
                                                return (
                                                    <Img 
                                                    fluid={photo.node.childImageSharp.fluid}
                                                    key={photo.node.childImageSharp.id}
                                                    />
                                                )
                                            } else return null;
                                        })}
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mx-auto">
                                <Nav variant="pills" className="justify-content-center" onSelect={handleSelect} >
                                    {products.photos.map((photo) => (
                                        <Nav.Item key={photo.node.childImageSharp.id}>
                                            <Nav.Link
                                                eventKey={photo.node.childImageSharp.id}
                                                key={photo.node.childImageSharp.id}
                                                className="m-1"
                                            >
                                                <Img
                                                    fluid={photo.node.childImageSharp.fluid}
                                                    key={photo.node.childImageSharp.id}
                                                    style={{ width: "4rem" }}
                                                />
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </Col>
                        </Row>
                    </TabContainer>
                </Col>

                <Col lg={5} className="mt-5">
                    <h1 className="p-4" id="product-title">{products.name}</h1>
                    <p className="p-2 lead">{products.description}</p>
                    <p className="p-2 lead">Materials: {products.material}</p>
                    <p className="lead">Sizes: {products.sizes}</p>
                </Col>
            </Row>
        </Layout>
    )
}

export default ProductDetail

export const pageQuery = graphql`
    query ProductPageQuery ($productId: ID!){
        gcms {
            productsApi (where: {id: $productId}) {
              id
              name
              description
              material
              sizes
              slug
              photos {
                url
                node {
                  childImageSharp {
                    id
                    fluid (sizes: "(maxWidth: 500px) calc(100vw-40px), 500px") {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
        }
    }
`